<template>
    <div class="page-body add-svg-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app section-body">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
                >
                    <div class="w-30 d-flex justify-content-between align-items-center">
                        <span class="label-app-list-header">Add SVG File</span>
                    </div>
                </div>

                <ValidationObserver ref="form">
                    <form style="box-shadow: none" class="p-0">
                        <div class="my-3">
                            <Input
                                label="Default Title"
                                labelClass="w-50 me-3 d-flex justify-content-end add-new-app-label"
                                class="w-80 field-container d-flex align-items-center mb-4"
                                type="text"
                                name="default title"
                                placeholder="Enter default Title"
                                v-model="fileData.name"
                                :disabled="true"
                            />
                            <Input
                                label="Set New Title"
                                labelClass="w-50 me-3 d-flex justify-content-end add-new-app-label"
                                class="w-80 field-container d-flex align-items-center mb-4"
                                type="text"
                                name="Title"
                                placeholder="Enter new Title"
                                v-model="fileData.title"
                                required
                            />
                            <span class="field-container d-flex align-items-center mt-2 mb-4 w-80">
                                <label class="w-50 me-3 d-flex add-new-app-label" style="justify-content: end"
                                    >Categories*</label
                                >
                                <Multiselect
                                    track-by="type"
                                    label="name"
                                    class="position-relative"
                                    placeholder="Choose Categories"
                                    :options="categoriesList"
                                    v-model="categoriesInfo"
                                    @input="fileData.categories = $event.type"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    required
                                />
                            </span>
                        </div>

                        <div class="section-footer add-new-app-form mt-4">
                            <div class="d-flex justify-content-center" style="bottom: 10%">
                                <button type="button" class="primary btn-save" @click="save">Save</button>
                                <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';

    const categoriesList = [
        {
            name: 'Arrows',
            type: 'Arrows',
        },
        {
            name: 'Devices & Phones',
            type: 'Devices & Phones',
        },
        {
            name: 'Doctor',
            type: 'Doctor',
        },
        {
            name: 'Food',
            type: 'Food',
        },
        {
            name: 'Forms',
            type: 'Forms',
        },
        {
            name: 'Hands',
            type: 'Hands',
        },
        {
            name: 'Houses',
            type: 'Houses',
        },
        {
            name: 'Maternal',
            type: 'Maternal',
        },
        {
            name: 'Medical',
            type: 'Medical',
        },
        {
            name: 'Miscellaneous',
            type: 'Miscellaneous',
        },
        {
            name: 'People',
            type: 'People',
        },
        {
            name: 'Places',
            type: 'Places',
        },
        {
            name: 'Search',
            type: 'Search',
        },
        {
            name: 'Transportation',
            type: 'Transportation',
        },
        {
            name: 'World',
            type: 'World',
        },
    ];

    export default {
        name: 'AddSvgModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            fileInfo: {
                type: File,
            },
        },
        data() {
            return {
                categoriesList,
                categoriesInfo: '',
                fileData: {
                    name: '',
                    title: '',
                    categories: '',
                },
            };
        },
        methods: {
            async save() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();

                if (!isFormValid || this.categoriesInfo === '') {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }

                this.$emit('cancel');

                const formData = new FormData();
                formData.append('file', this.fileInfo);
                formData.append('title', this.fileData.title);
                formData.append('categories', this.fileData.categories);

                this.$emit('handleSave', formData);
            },
        },
        created() {
            this.fileData = { name: this.fileInfo?.name?.split('.')[0] };
        },
    };
</script>

<style scoped>
    .modal-content-header {
        font-size: 18px;
        color: #2e3343;
        margin: 0 0 20px;
        background-color: #f1f7ff;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .add-svg-modal .modal__content {
        padding: 0;
        border-radius: 10px;
    }
</style>
